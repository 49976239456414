import React from "react"
import { HeaderLinksList, HeaderLink } from "./navbarMenuLinks"
import styles from "./navBarMenuBar.module.scss"
import logo from "../../images/mrc-logo-colour.png"

function NavbarMenuBar() {
    return (
        <div className={styles.menu_bar}>
            <div className={"navbar container " + styles.mainHeaderStyle}>
                <div className={"nav " + styles.navbarLogoContainer}>
                    <img className={styles.navbarLogo} src={logo} alt="MRC Logo" />
                </div>
                <div className={"nav show " + styles.floatRight + " " + styles.navbarText}>
                    <HeaderLinksList>
                        <HeaderLink text="Animal Search" link="/" />
                        <HeaderLink text="Gallery" link="/gallery" />
                        <HeaderLink text="Contact Us" link="/contactus" />
                    </HeaderLinksList>
                </div>
            </div>
        </div>
    )
}

export default NavbarMenuBar