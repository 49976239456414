import React from "react"
import styles from "./socialMediaContainer.module.scss"
import stayConnected from "../../images/stay-connected.png"
import facebookIcon from "../../images/facebook-icon.png"
import instagramIcon from "../../images/instagram-icon.png"
import linkedInIcon from "../../images/linkedIn-icon.png"
import pinterestIcon from "../../images/pinterest-icon.png"
import twitterIcon from "../../images/twitter-icon.png"
import youTubeIcon from "../../images/youTube-icon.png"

function SocialMediaContainer() {
    return (
        <div className={"col-lg-6 col-md-12 col-sm-12 col-12 " + styles.socialLinksContainer}>
            <div className={styles.socialLinksArea}>

                <img className={styles.footerStayConnected} src={stayConnected} alt="MRC Connected" />

                <div>
                    <SocialMediaLink href="https://www.pinterest.com.au/mackayregionalcouncil/" src={pinterestIcon} alt="Pinterest Icon" />
                    <SocialMediaLink href="https://www.instagram.com/mackaycouncil/" src={instagramIcon} alt="Instagram Icon" />
                    <SocialMediaLink href="https://www.youtube.com/user/counciltv" src={youTubeIcon} alt="Youtube Icon" />
                    <SocialMediaLink href="https://www.linkedin.com/company/mackayregionalcouncil?trk=NUS_CMPY_TWIT" src={linkedInIcon} alt="LinkedIn Icon" />
                    <SocialMediaLink href="https://twitter.com/mackaycouncil" src={twitterIcon} alt="Twitter Icon" />
                    <SocialMediaLink href="https://www.facebook.com/mackayregionalcouncil" src={facebookIcon} alt="Facebook Icon" />
                </div>

            </div>
        </div>
    )
}

interface SocialMediaLinkProps {
    href: string;
    src: string;
    alt: string;
}

function SocialMediaLink({ href, src, alt }: SocialMediaLinkProps) {
    return (
        <div className={styles.socialMediaLink}>
            <a href={href} target="_blank" rel="noreferrer">
                <img src={src} alt={alt} />
            </a>
        </div>
    )
}

export default SocialMediaContainer