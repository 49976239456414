import React from "react"
import { FooterLinksList, FooterLink } from "./footerLinksList"

function LinksContainer() {
    return (
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <FooterLinksList title="Quick Links">
                <FooterLink text="Contact Council" link="http://www.mackay.qld.gov.au/contact" />
                <FooterLink text="Privacy/Disclaimer" link="https://www.mackay.qld.gov.au/privacydisclaimer" />
            </FooterLinksList>
        </div>
    )
}

export default LinksContainer