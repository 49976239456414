import React, { useEffect, useState } from "react"
import styles from "./animalTable.module.scss"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import Modal from "react-modal"
import Animal from "../../../types/animal"
import LoadingSpinner from "./../loading-spinner/loadingSpinner"

Modal.setAppElement("#root")

function AnimalTable() {
    const [animals, setAnimals] = useState<Animal[]>([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [modalIsOpen, setIsModalOpen] = useState(false)
    const [modalImage, setModalImage] = useState("")
    const [searchTerm, setSearchTerm] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        axios
            .get("/api/animals")
            .then(res => {
                const data = res.data as Animal[]
                setAnimals(data)
                setIsLoaded(true)
            })
    }, [])

    function openModal(scr: string) {
        setModalImage(`/animalImages/${scr}`)
        setIsModalOpen(true)
    }

    function closeModal() {
        setIsModalOpen(false)
    }

    const filteredAnimals = animals.filter((animal) => {
        const lowerSearchTerm = searchTerm.toLowerCase()
        return (
            animal.Breed.toLowerCase().includes(lowerSearchTerm) ||
            animal.Desc.toLowerCase().includes(lowerSearchTerm)
        )
    })

    if (!isLoaded) return <LoadingSpinner />

    return (
        <>
            <div className={styles.search}>
                <input
                    type="text"
                    placeholder="Search by Breed or Description"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Animal Modal"
                className={styles.modal}
                overlayClassName={styles.overlay}
            >

                <div>
                    <div>
                        <img src={modalImage} alt="Animal Display" />
                    </div>
                    <div className={styles.action}>
                        <button type="button" onClick={closeModal}>Close</button>
                        <button type="button" onClick={() => navigate("/contactus")}>That&apos;s My Pet!</button>
                    </div>
                </div>

            </Modal>

            <div className={styles.tableContainer}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Impounded</th>
                            <th scope="col">Animal</th>
                            <th scope="col">Found</th>
                            <th scope="col">Breed</th>
                            <th scope="col">Description</th>
                            <th scope="col">Gender</th>
                            <th scope="col">Age</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredAnimals.map((item, index) => (
                            <tr key={index} onClick={() => openModal(item.ImagePath)}>
                                <td>{item.AnimalID}</td>
                                <td>{item.ImpoundedDate}</td>
                                <td>{item.AnimalType}</td>
                                <td>{item.Found}</td>
                                <td>{item.Breed}</td>
                                <td>{item.Desc}</td>
                                <td>{item.Gender}</td>
                                <td>{item.Age}</td>
                                <td>{item.Status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default AnimalTable