import React, { useState } from "react"
import styles from "./header.module.scss"
import NavbarMenuBar from "./navBarMenuBar"

function Header() {
    const [showAlert, setShowAlert] = useState(false)

    return (
        <header className={styles.header}>
            <NavbarMenuBar />
            {showAlert && (
                <div className={"alert alert-warning alert-dismissible fade show " + styles.alert}>
                    <div className={styles.alertText}>
                        <strong>Alert Title</strong><br></br>
                        Alert Message
                    </div>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowAlert(false)}
                        aria-label="Close"
                    ></button>
                </div>
            )}
        </header>
    )
}

export default Header