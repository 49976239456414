import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./app.scss"

import Header from "./components/header/header"
import Body from "./components/body/body"
import Footer from "./components/footer/footer"

import background from "./images/background.jpg"

function App() {
    return (
        <>
            <img className="bgImage" src={background} alt="Illustration" />

            <div className="PageContainer">
                <Header />
                <Body />
                <Footer />
            </div>
        </>
    )
}

export default App
