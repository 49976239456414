import React, { useEffect, useState } from "react"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/scss/image-gallery.scss"
import axios from "axios"
import Animal from "../../../types/animal"
import LoadingSpinner from "./../loading-spinner/loadingSpinner"
import "./gallery.scss"

function Gallery() {
    const [images, setImages] = useState<any[]>([])
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        axios
            .get("/api/animals")
            .then(res => {
                const data = res.data as Animal[]

                const images = data.map(animal => {
                    var imageUrl = `/animalImages/${animal.ImagePath}`
                    return {
                        original: imageUrl,
                        thumbnail: imageUrl,
                        description: `Animal ID:${animal.AnimalID}`
                    }
                })

                setImages(images)
                setIsLoaded(true)
            })
    }, [])

    if (!isLoaded) return <LoadingSpinner />

    return (
        <div className="gallery">
            <ImageGallery items={images} thumbnailPosition="top" />
        </div>
    )
}

export default Gallery