import React from "react"
import { Routes, Route } from "react-router-dom"
import styles from "./body.module.scss"

import Title from "./title"
import Gallery from "./gallery/gallery"
import ContactInfo from "./contact-info/contactInfo"
import AnimalTable from "./animal-table/animalTable"


function Body() {
    return (
        <main className={"container " + styles.body}>
            <div>
                <div>
                    <Routes>
                        <Route path='/' element={<Title text="Animal Register" />} />
                        <Route path='/gallery' element={<Title text="Gallery" />} />
                        <Route path='/contactus' element={<Title text="Contact Information" />} />
                    </Routes>
                </div>
                <Routes>
                    <Route path='/' element={<AnimalTable />} />
                    <Route path='/gallery' element={<Gallery />} />
                    <Route path='/contactus' element={<ContactInfo />} />
                </Routes>
            </div>
        </main>
    )
}

export default Body