import React from "react"
import styles from "./navbarMenuLinks.module.scss"

interface HeaderLinksListProps {
    children: React.ReactNode;
}

function HeaderLinksList({ children }: HeaderLinksListProps) {
    return (
        <ul className={styles.menu}>
            {children}
        </ul>
    )
}

interface HeaderLinkProps {
    link: string;
    text: string;
}

function HeaderLink({ link, text }: HeaderLinkProps) {
    return (
        <li>
            <a className={styles.menuItem} href={link} rel="noreferrer">{text}</a>
        </li>
    )
}

export { HeaderLinksList, HeaderLink }