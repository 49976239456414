import React from "react"
import styles from "./footerContainer.module.scss"
import LinksContainer from "./linksContainer"
import SocialMediaContainer from "./socialMediaContainer"

function FooterContainer() {
    return (
        <div className={"row container " + styles.footerContainer}>
            <LinksContainer />
            <SocialMediaContainer />
        </div>
    )
}

export default FooterContainer