import React from "react"
import styles from "./footerLinksList.module.scss"

interface FooterLinksListProps {
    title: string;
    children: React.ReactNode;
}

function FooterLinksList({ title, children }: FooterLinksListProps) {
    return (
        <ul className={styles.footerLinks}>
            <li className={styles.linkHeader}>{title}</li>

            {children}

        </ul>
    )
}

interface FooterLinkProps {
    link: string;
    text: string;
}

function FooterLink({ link, text }: FooterLinkProps) {
    return (
        <li>
            <a className={styles.link} href={link} rel="noreferrer">{text}</a>
        </li>
    )
}

export { FooterLinksList, FooterLink }