import React from "react"
import styles from "./footer.module.scss"
import FooterContainer from "./footerContainer"

function Footer() {
    return (
        <footer className={styles.footerArea}>
            <FooterContainer />
        </footer>
    )
}

export default Footer