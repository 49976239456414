import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import "./index.scss"
import App from "./app"

import "bootstrap/dist/css/bootstrap.min.css"

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
)