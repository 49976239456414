import React from "react"
import styles from "./title.module.scss"

interface TitleProps {
    text: string;
}

function Title({ text }: TitleProps) {
    return (
        <div>
            <div>
                <h2 className={styles.titleText}>{text}</h2>
            </div>
        </div>
    )
}

export default Title