import React from "react"
import styles from "./loadingSpinner.module.scss"

function LoadingSpinner() {
    return (
        <div className={styles.container}>
            <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border text-primary" role="status" />
                <span className="ms-3">Loading...</span>
            </div>
        </div>
    )
}

export default LoadingSpinner