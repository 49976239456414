import React from "react"
import styles from "./contactInfo.module.scss"

import creditCardImage from "../../../images/creditcards.png"

function ContactInfo() {
    return (
        <>
            <div className={styles.text}>
                <p>Mackay Regional Council operates an Animal Management Centre located across from the Mount Bassett Cemetery on Strickland Road, Mackay Harbour.</p>

                <p>If you have identified one of these animals as yours, please visit the Animal Management Centre during opening hours to collect your pet, or call the number below for more information.</p>

                <p>
                    <b>You will need to supply a form of photo identification (e.g. drivers licence) upon collection.</b>
                </p>

                <p>
                    <b>No Cash Accepted.We can accept payments by Card Only.</b><br></br>
                    <img src={creditCardImage} alt="CrCard" />
                </p>

                <p>If you are interested in adopting an animal that cannot be reunited with its owner, please call the number below.</p>

                <p>
                    <b><u>Phone: 1300 MACKAY or 1300 622 529</u></b>
                </p>

                <p>
                    <b>Opening hours are Monday to Friday from 11: 30am to 1: 30pm</b>
                </p>

            </div>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2212.975467562255!2d149.20670740028538!3d-21.121882337769353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6bdbd6982a1be557%3A0x4c129983596a38d5!2sMackay%20Regional%20Council%20Animal%20Management%20Centre!5e0!3m2!1sen!2sau!4v1601587608754!5m2!1sen!2sau"
                width="100%" height="500" title="Animal Management Center Map"
                allowFullScreen={false} aria-hidden="false" tabIndex={0}>
            </iframe>
        </>
    )
}

export default ContactInfo